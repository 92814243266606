<template>
  <div class="solutions-swiper-box">
    <div class="solutions-swiper-info" @click="solutionsInfo($event)">
      <template v-for="(el, index) in productList">
        <div
          v-if="el.id == swiperId"
          :key="index"
          class="solutions-gallery-top-list"
        >
          <div class="solutions-swiper-info-box">
            <span :data-id="el.id" data-type="iot">
              <v-img :data-id="el.id" data-type="iot" :attachment-id="el.attmIds" :lazy="false" :default-img="require('@/assets/img/solution/kctp.png')" />
            </span>
            <div>
              <div class="solutions-swiper-info-title">
                <p :data-id="el.id" data-type="iot">{{el.name}}</p>
                <span :data-id="el.id" data-type="iot">查看详情 ></span>
              </div>
              <div class="solutions-swiper-info-lable">
                <template v-if="el.datSocList">
                  <div v-for="i in el.datSocList" :key="i.id" :data-id="i.id" data-type="soc">
                    <img alt="图片" src="@/assets/img/solution/icon3.png" />
                    <p>SOC：{{i.name}}</p>
                  </div>
                </template>
                <div v-if="el.datDevboard" :data-id="el.datDevboard.id" data-type="dev">
                  <img alt="图片" src="@/assets/img/solution/icon2.png" />
                  <p>开发板：{{el.datDevboard.name}}</p>
                </div>
                <template v-if="el.datOsList">
                  <div v-for="i in el.datOsList" :key="i.id" :data-id="i.id" data-type="os">
                    <img alt="图片" src="@/assets/img/solution/icon4.png" />
                    <p>嵌入式OS：{{i.name}}</p>
                  </div>
                </template>
              </div>
              <p><em></em><span>入网方式</span>{{el.connMode || '其他'}}</p>
              <p><em></em><span>网络协议</span>{{el.networkProtocol || '其他'}}</p>
              <p><em></em><span>加密方式</span>{{el.cipherMode || '其他'}}</p>
              <p><em></em><span>硬件尺寸</span>{{el.hardwareSize || '其他'}}</p>
<!--              <p><em></em><span>场&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;景</span>{{el.sceneTag}}</p>-->
              <p><em></em><span>场&#12288;&#12288;景</span>{{el.sceneTagName}}</p>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="solutions-swiper-tab">
      <swiper
        ref="swiperThumbs"
        class="swiper solutions-gallery-thumbs subswiper-box"
        :options="swiperOptionThumbs"
      >
        <swiper-slide
          v-for="(el, index) in productList"
          :key="index"
          :class="{'active':swiperId == el.id}"
          class="solutions-gallery-thumbs-list"
        >
          <div class="solutions-swiper-tab-info" @click="solutionSubSwiper(el.id)">
            <span><v-img :attachment-id="el.attmIds" :lazy="false" :default-img="require('@/assets/img/solution/kctp.png')" /></span>
            <div>{{el.name}}</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-next" :style="{'opacity':productList.length > 4?'1':'0'}">
          <a-icon type="right" />
      </div>
      <div class="swiper-prev" :style="{'opacity':productList.length > 4?'1':'0'}">
          <a-icon type="left" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VImg from "@/components/VImg";
import { getIotProductInfo } from "@/api/iot";
export default {
  props:['val'],
  name: "SolutionCover",
  components: {
    Swiper,
    SwiperSlide,
    VImg
  },
  computed: {
    swiperOptionThumbs() {
      return {
        spaceBetween: 24,
        slidesPerView: 4,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      };
    },
  },
  data(){
    return{
      productList:[],
      swiperId:''
    }
  },
  mounted() {
    let data = this.val
    for (let index = 0; index < data.length; index++) {
      if(data[index]){
        getIotProductInfo(data[index].id).then((res)=>{
          data[index].datSoc = res.datSoc
          data[index].datDevboard = res.datDevboard
          data[index].datOs = res.datOs
        })
      }else{
        data.splice(index,1)
      }
    }
    this.productList = data
    this.swiperId = data[0].id
  },
  methods:{
    solutionSubSwiper(e){
      this.swiperId = e
    },
    solutionsInfo(e){
      if(!e.target.dataset.id){
        return
      }
      let id = e.target.dataset.id
      let type = e.target.dataset.type
      if(type == 'iot'){
        this.$emit('product',id)
      }else{
        const routeOne = this.$router.resolve({
          path: `/replace/productInfo/${type}`,
          query: { id },
        })
        window.open(routeOne.href, '_blank')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.solutions-swiper-box {
  .solutions-swiper-info {
    margin-bottom: 8px;
    .solutions-swiper-info-box {
      background: #fff;
      padding: 40px;
      display: flex;
      > span {
        width: 280px;
        height: 280px;
        margin-right: 40px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      > div {
        flex: 1;
        .solutions-swiper-info-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          > p {
            font-size: 24px;
            line-height: 30px;
          }
          > span {
            cursor: pointer;
            color: #ea0b06;
          }
        }
        .solutions-swiper-info-lable {
          display: flex;
          margin-bottom: 28px;
          > div {
            max-width: 260px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            padding: 0 16px;
            display: flex;
            align-items: center;
            margin-left: 16px;
            cursor: pointer;
            transition: all 0.3s;
            &:first-child {
              margin-left: 0;
            }
            > img {
              margin-right: 8px;
            }
            >p{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
            &.active {
              background: #EA0B06;
              border-color: #EA0B06;
              color: #fff;
            }
          }
        }
        > p {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          line-height: 19px;
          &:last-child {
            margin-bottom: 0;
          }
          > em {
            width: 6px;
            height: 6px;
            background: #EA0B06;
            border-radius: 50%;
            margin-right: 8px;
          }
          > span {
            color: #8c8c8c;
            margin-right: 30px;
          }
        }
      }
    }
  }
  .solutions-swiper-tab{
      position: relative;
      .swiper-next,.swiper-prev{
          position: absolute;
          top: 16px;
          bottom: 0;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, .16);
          color: #fff;
          font-size: 14px;
          cursor: pointer;
      }
      .swiper-prev{
          left: -56px;
      }
      .swiper-next{
          right: -56px;
      }
  }
  .solutions-swiper-tab-info {
    position: relative;
    background: #fff;
    padding: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      width: 80px;
      height: 80px;
      margin-right: 16px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > div {
      flex: 1;
    }
  }
}
</style>

<style>
.solutions-swiper-tab .swiper-container {
  padding-top: 16px;
}
.solutions-gallery-thumbs-list.active .solutions-swiper-tab-info {
  background: #EA0B06;
}
.solutions-gallery-thumbs-list.active .solutions-swiper-tab-info::after {
  content: "";
  position: absolute;
  display: block;
  border: 8px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #EA0B06;
  top: -16px;
  left: 16px;
  z-index: 2;
}
.solutions-gallery-thumbs-list.active .solutions-swiper-tab-info > div {
  color: #fff;
}
</style>
