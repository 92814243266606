<template>
  <div class="home-solution-jiagou">
    <div class="home-solution-frame">
      <div style="top: 148px;left: 423px;z-index: 5;">
        <div v-for="item in frameDataA" :key="item.id" @click="frameData(item)">
          <img alt="图片" :src="item.appIcon">
          <p :class="{'active':item.pdtIds != null}">
            <img alt="图片" v-if="item.pdtIds != null" src="@/assets/img/solution/jth.png" class="red"/>
            <img alt="图片" v-else src="@/assets/img/solution/jthui.png" class="gray"/>
            {{item.appName}}
          </p>
        </div>
      </div>
      <div style="top: 200px;left: 336px;z-index: 4;">
        <div v-for="item in frameDataB" :key="item.id" @click="frameData(item)">
          <img alt="图片" :src="item.appIcon">
          <p :class="{'active':item.pdtIds != null}">
            <img alt="图片" v-if="item.pdtIds != null" src="@/assets/img/solution/jth.png" class="red"/>
            <img alt="图片" v-else src="@/assets/img/solution/jthui.png" class="gray"/>
            {{item.appName}}
          </p>
        </div>
      </div>
      <div style="top: 256px;left: 250px;z-index: 3;">
        <div v-for="item in frameDataC" :key="item.id" @click="frameData(item)">
          <img alt="图片" :src="item.appIcon">
          <p :class="{'active':item.pdtIds != null}">
            <img alt="图片" v-if="item.pdtIds != null" src="@/assets/img/solution/jth.png" class="red"/>
            <img alt="图片" v-else src="@/assets/img/solution/jthui.png" class="gray"/>
            {{item.appName}}
          </p>
        </div>
      </div>
      <div style="top: 330px;left: 116px;z-index: 2;">
        <div v-for="item in frameDataD" :key="item.id" @click="frameData(item)">
          <img alt="图片" :src="item.appIcon">
          <p :class="{'active':item.pdtIds != null}">
            <img alt="图片" v-if="item.pdtIds != null" src="@/assets/img/solution/jth.png" class="red"/>
            <img alt="图片" v-else src="@/assets/img/solution/jthui.png" class="gray"/>
            {{item.appName}}
          </p>
        </div>
      </div>
      <div style="top: 384px;left: 24px;z-index: 1;">
        <div v-for="item in frameDataE" :key="item.id" @click="frameData(item)">
          <img alt="图片" :src="item.appIcon">
          <p :class="{'active':item.pdtIds != null}">
            <img alt="图片" v-if="item.pdtIds != null" src="@/assets/img/solution/jth.png" class="red"/>
            <img alt="图片" v-else src="@/assets/img/solution/jthui.png" class="gray"/>
            {{item.appName}}
          </p>
        </div>
      </div>
    </div>
    <div class="home-solution-frame-info-box">
      <p>鸿蒙化替代参考 | <span style="color:#EA0B06">{{frameDatas.appName}}</span></p>
      <div>
        <div class="home-solution-frame-info" :class="{'active':frameInfoDev}">
          <div class="home-solution-frame-info-title" @click="frameInfoDev != frameInfoDev">
            <p><img alt="图片" src="@/assets/img/solution/icon2.png" />开发板</p>
            <a-icon type="down" />
          </div>
          <div class="home-solution-frame-info-content" >
            <div v-for="item in devData" :key="item.id">
              <span><v-img :attachment-id="item.attmIds" :default-img="require('@/assets/img/solution/kctp.png')" /></span>
              <div>
                <p><span>名称：</span>{{item.name}}</p>
                <p><span>型号：</span>{{item.modelNum}}</p>
                <span @click="solutionFrameInfo(item.id,'dev')">查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <div class="home-solution-frame-info" :class="{'active':frameInfoSoc}">
          <div class="home-solution-frame-info-title" @click="frameInfoSoc = !frameInfoSoc">
            <p><img alt="图片" src="@/assets/img/solution/icon3.png" />SOC</p>
            <a-icon type="down" />
          </div>
          <div class="home-solution-frame-info-content">
            <div v-for="item in socData" :key="item.id" @click="devInfo(item.id)">
              <span><v-img :attachment-id="item.socImg" :default-img="require('@/assets/img/solution/kctp.png')" /></span>
              <div>
                <p><span>名称：</span>{{item.name}}</p>
                <p><span>型号：</span>{{item.modelNum}}</p>
                <span @click="solutionFrameInfo(item.id,'soc')">查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <div class="home-solution-frame-info" :class="{'active':frameInfoOs}">
          <div class="home-solution-frame-info-title" @click="frameInfoOs = !frameInfoOs">
            <p><img alt="图片" src="@/assets/img/solution/icon4.png" />嵌入OS</p>
            <a-icon type="down" />
          </div>
          <div class="home-solution-frame-info-content">
            <div v-for="item in osData" :key="item.id" @click="devInfo(item.id)">
              <span><v-img :attachment-id="item.osImg" :default-img="require('@/assets/img/solution/kctp.png')" /></span>
              <div>
                <p><span>名称：</span>{{item.name}}</p>
                <p><span>适配SOC：</span>{{item.socMapping}}</p>
                <span @click="solutionFrameInfo(item.id,'os')">查看详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { getProductInfo } from "@/api/solution";
export default {
  components: {
    VImg,
  },
  props:['val'],
  data(){
    return{
      frameInfoIndex:0,
      frameDataA:[],
      frameDataB:[],
      frameDataC:[],
      frameDataD:[],
      frameDataE:[],
      devData:[],
      socData:[],
      osData:[],
      frameDatas:'',
      frameInfoDev:true,
      frameInfoSoc:true,
      frameInfoOs:true
    }
  },
  mounted(){
    let _this = this
    this.$nextTick(() =>{
      setTimeout(() => {
        _this.getframeData()
      }, 300);
    })
  },
  methods:{
    getframeData(){
      let {val} = this
      let isPdtIds = true
      val.forEach(element => {
        if(element.layerType == 1){
          this.frameDataA.push(element)
        }else if(element.layerType == 2){
          this.frameDataB.push(element)
        }else if(element.layerType == 3){
          this.frameDataC.push(element)
        }else if(element.layerType == 4){
          this.frameDataD.push(element)
        }else if(element.layerType == 5){
          this.frameDataE.push(element)
        }
        if(element.pdtIds != null){
          element.appIcon =  element.appIcon.replace(/gzc/g,'gzc_red')
        }
        if(element.pdtIds && isPdtIds){
          isPdtIds = false
          this.frameData(element)
          this.frameDatas = element
        }
      });
    },
    solutionFrameInfo(id,type){
      this.$router.push({
        path: `/replace/productInfo/${type}`,
        query: { id },
      });
    },
    frameData(val){
      if(val.pdtIds == null){
        return
      }
      this.frameInfoIndex = 1
      this.frameDatas = val
      let data = {
        pdtIds:val.pdtIds
      }
      getProductInfo(data).then((res)=>{
        this.devData = res.datDevboardList
        this.socData = res.datSocList
        this.osData = res.datOsList
      })
    },
    frameInfo(index){
      this.frameInfoIndex = this.frameInfoIndex == index?0:index
    }
  }
};
</script>

<style lang="scss" scoped>
.home-solution-jiagou {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .home-solution-frame {
    width: 845px;
    height: 541px;
    background: url(../../../assets/img/solution/gs2.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    > div {
      display: flex;
      transform: rotate(30deg);
      width: 420px;
      position: absolute;
      > div {
        margin-left: 24px;
        transform: rotate(-30deg);
        cursor: pointer;
        position: relative;
        >p{
          position: absolute;
          height: 20px;
          border: 1px solid #666666;
          padding: 0 10px;
          font-size: 12px;
          color: #666;
          line-height: 16px;
          top: 50px;
          right: 0;
          white-space: nowrap;
          background: #fff;
          z-index: 10;
          >img{
            position: absolute;
            right: 10px;
            top: -6px;
            &.red{
              display: none;
            }
          }
          &.active{
            color: #EA0B06;
            border-color: #EA0B06;
            >img{
              &.red{
                display: block;
              }
              &.gray{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .home-solution-frame-info-box{
    width: 420px;
    height: 620px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    overflow-y: auto;
    >p{
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    >div{
      .home-solution-frame-info{
        margin-bottom: 18px;
        .home-solution-frame-info-title{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          cursor: pointer;
          >p{
            display: flex;
            align-items: center;
            line-height: 20px;
            font-size: 16px;
            >img{
              margin-right: 12px;
            }
          }
          >i{
            color: #EA0B06;
            font-size: 12px;
            transition: all .3s;
          }
        }
        .home-solution-frame-info-content{
          max-height: 351px;
          overflow-x: auto;
          display: none;
          >div{
            display: flex;
            padding: 14px 16px;
            border-bottom: 1px solid #f0f0f0;
            >span{
              width: 68px;
              height: 68px;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              >img{
                max-width: 100%;
              }
            }
            >div{
              flex: 1;
              >p{
                font-size: 12px;
                display: flex;
                line-height: 16px;
                margin-bottom: 6px;
                >span{
                  color: #999;
                }
              }
              >span{
                height: 22px;
                border-radius: 3px;
                border: 1px solid #333333;
                line-height: 20px;
                padding: 0 12px;
                font-size: 12px;
                display: inline-block;
                cursor: pointer;
                transition: all .3s;
              }
            }
            &:hover{
              >div{
                >span{
                  background: #EA0B06;
                  border-color: #EA0B06;
                  color: #fff;
                }
              }
            }
          }
        }
        &.active{
          .home-solution-frame-info-title{
            >i{
              transform: rotate(-180deg);
            }
          }
          .home-solution-frame-info-content{
            display: block;
          }
        }
      }
    }
  }
}
</style>
