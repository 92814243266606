<template>
  <div class="home-solution-case-list" v-if="val != ''">
    <template v-for="(item,index) in val">
      <div :key="item.id" v-if="index < 1">
        <span><v-img :attachment-id="item.attmIds" :default-img="require('@/assets/img/solution/kctp.png')" /></span>
        <div>
          <p>{{item.name}}</p>
          <span>{{item.caseDiscription}}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  components:{
    VImg
  },
  props: ["val"],
};
</script>

<style lang="scss" scoped>
.home-solution-case-list {
  > div {
      display: flex;
      > div {
        flex:1;
        padding: 24px 40px;
        > p {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
          color: #333;
        }
        > span {
          font-size: 16px;
          display: block;
          line-height: 24px;
          color: #666;
          text-align: justify;
        }
      }
      >span{
        width: 630px;
        height: 354px;
        >img{
            width: 100%;
            height: 100%;
        }
      }
    }
  /* > div {
    position: relative;
    width: 630px;
    height: 354px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
    > div {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      padding: 10px 16px;
      left: 0;
      right: 0;
      bottom: 0;
      > p {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px;
      }
      > span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        text-align: justify;
        max-height: 32px;
        overflow: hidden;
      }
    }
  } */
}
</style>
<style>
.home-solution-case-text{
  max-width: 598px;
}
</style>
